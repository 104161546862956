<template>
  <div>
    <div
      ref="g-gantt-bar"
      :class="[
        'g-gantt-bar',
        {
          'g-gantt-bar-immobile': isLocalImmobile,
          active: activeBars.includes(bar.id),
        },
      ]"
      :style="barStyle"
      @mouseenter.stop="onMouseenter($event)"
      @mouseleave.stop="onMouseleave($event)"
      @mousedown.stop="onMousedown($event)"
      @click.stop="onClick($event)"
      @dblclick="onDblclick($event)"
      @contextmenu="onContextmenu($event)"
    >
      <div class="g-gantt-bar-label">
        <slot name="bar-label" :bar="bar">
          {{ barConfig.label || '' }}
        </slot>
      </div>
      <template v-if="barConfig.handles">
        <div
          :class="[
            'g-gantt-bar-handle-left',
            { disabled: isHandleLeftDisabled },
          ]"
        />
        <div
          :class="[
            'g-gantt-bar-handle-right',
            { disabled: isHandleRightDisabled },
          ]"
        />
      </template>
    </div>

    <transition name="fade" mode="out-in">
      <div
        v-if="!barConfig.noTooltip && (showTooltip || isDragging)"
        class="g-gantt-tooltip"
        :style="tooltipStyle"
      >
        <!--        <div-->
        <!--          class="color-indicator"-->
        <!--          :style="{-->
        <!--            padding: '0',-->
        <!--            width: 0,-->
        <!--          }"-->
        <!--        />-->
        <div class="m-b-5">Время: {{ barStartText }} - {{ barEndText }}</div>
        <div class="m-b-5">Культура: {{ getCultureName }}</div>
        <div class="m-b-5">Кол-во: {{ bar.buffer_quota }}</div>
        <div :class="{ 'm-b-5': getBarExporter }">
          Тип авто: {{ barTruckTypes }}
        </div>
        <div v-if="getBarExporter">Поставщик: {{ getBarExporter }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { DATE_FORMAT_WITHOUT_MINUTES } from '@/constants/date'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { getDateToUnix, getTime, now, nowUnix } from '@/core'
import { mapGetters } from 'vuex'
import moment from '@/core/date'

export default {
  name: 'GGanttBar',

  inject: [
    'getTimeCount',
    'ganttChartProps',
    'initDragOfBarsFromBundle',
    'moveBarsFromBundleOfPushedBar',
    'setDragLimitsOfGanttBar',
    'onBarEvent',
    'onDragendBar',
    'getMinGapBetweenBars',
    'getTimeUnit',
    'getTimeFormat',
  ],

  props: {
    bar: { type: Object },
    barContainer: [Object, DOMRect],
    allBarsInRow: { type: Array },
    rowId: { type: Number, default: null },
    activeBars: {
      type: Array,
      default: () => [],
    },
    isImmobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showTooltip: false,
      tooltipTimeout: null,
      dragLimitLeft: null,
      dragLimitRight: null,
      isDragging: false,
      isMainBarOfDrag: false, // is this the bar that was clicked on when starting to drag or is it dragged along some other bar from the same bundle
      cursorOffsetX: 0,
      mousemoveCallback: null, // gets initialized when starting to drag, possible values: drag, dragByHandleLeft, dragByHandleRight,
      barStartBeforeDrag: null,
      barEndBeforeDrag: null,
      timeUnit: this.getTimeUnit(),
      timeChildKey:
        this.ganttChartProps.precision === 'month' ? 'hours' : 'minutes',
      timeChildFormat:
        this.ganttChartProps.precision === 'month' ? 'MM-DD' : 'HH:mm',
      timeFormat: this.getTimeFormat(),
      barStartKey: this.ganttChartProps.barStartKey,
      barEndKey: this.ganttChartProps.barEndKey,
    }
  },

  computed: {
    ...mapGetters({
      getPrepareCultures: GET_PREPARE_CULTURE_FROM_STATE,
      suppliers: GET_SUPPLIERS_ALL,
    }),
    barStartMoment: {
      get() {
        return moment(this.bar[this.barStartKey], this.timeFormat)
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.bar[this.barStartKey] = value.format(this.timeFormat)
      },
    },

    barEndMoment: {
      get() {
        return moment(this.bar[this.barEndKey])
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.bar[this.barEndKey] = value.format(this.timeFormat)
      },
    },

    barStartText: {
      get() {
        return moment(this.barStartMoment).format(this.timeChildFormat)
      },
    },

    barEndText: {
      get() {
        let endMoment = moment(this.barEndMoment)

        return endMoment.format(this.timeChildFormat)
      },
    },

    barTruckTypes() {
      if (
        this.bar.truck_types.length === autoTypeSupervisor.length ||
        !this.bar.truck_types.length
      ) {
        return 'Любые'
      }
      let typeNames = []

      this.bar.truck_types.forEach(item => {
        typeNames.push(autoTypeSupervisor.find(name => name.id === item).name)
      })

      return typeNames.join(', ')
    },

    barConfig() {
      return {
        background: '#ccc',
        opacity: '0.9',
        color: '#333',
        handles: true,
        noTooltip: false,
        isShadow: false,
        pushOnOverlap: true,
      }
    },

    barStyle() {
      if (!this.barContainer.width) {
        return
      }

      let xStart = this.mapTimeToPosition(this.barStartMoment)
      let xEnd = this.mapTimeToPosition(this.barEndMoment)

      return {
        ...(this.barConfig || {}),
        left: `${xStart}px`,
        width: `${xEnd - xStart}px`,
        height: `${this.ganttChartProps.rowHeight - 6}px`,
        zIndex: this.barConfig.zIndex || (this.isDragging ? 2 : 1),
      }
    },

    tooltipStyle() {
      return {
        left: this.barStyle.left,
        top: `${this.ganttChartProps.rowHeight}px`,
      }
    },

    chartStartMoment() {
      return moment(this.ganttChartProps.chartStart)
    },

    chartEndMoment() {
      return moment(this.ganttChartProps.chartEnd)
    },
    getBarExporter() {
      const supplier = this.suppliers.filter(
        item => item.id === this.bar?.suppliers_ids[0],
      )

      return supplier.length > 0 ? `${supplier[0]?.name}` : ''
    },

    getCultureName() {
      if (this.bar.culture_id === null) {
        return 'Любая'
      }

      return `${this.getPrepareCultures[this.bar.culture_id]?.name}
       ${
         this.getPrepareCultures[this.bar.culture_id]?.harvest_year
           ? this.getPrepareCultures[this.bar.culture_id]?.harvest_year +
             ' года'
           : ''
       }`
    },
    isLocalImmobile() {
      return this.isImmobile || getDateToUnix(this.bar.time_to) < nowUnix()
    },
    isHandleLeftDisabled() {
      return (
        getDateToUnix(this.bar.time_from) <
        getDateToUnix(getTime(now(), DATE_FORMAT_WITHOUT_MINUTES))
      )
    },
    isHandleRightDisabled() {
      return (
        getDateToUnix(this.bar.time_to) <
        getDateToUnix(getTime(now(), DATE_FORMAT_WITHOUT_MINUTES))
      )
    },
  },

  methods: {
    onMouseenter(e) {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout)
      }
      this.tooltipTimeout = setTimeout(() => (this.showTooltip = true), 800)
      this.onBarEvent({ event: e, type: e.type, rowId: this.rowId }, this)
    },

    onMouseleave(e) {
      clearTimeout(this.tooltipTimeout)
      this.showTooltip = false
      this.onBarEvent({ event: e, type: e.type, rowId: this.rowId }, this)
    },

    onContextmenu(e) {
      const time = this.mapPositionToTime(
        e.clientX - this.barContainer.left,
      ).format(this.timeFormat)

      this.onBarEvent({ event: e, type: e.type, time, rowId: this.rowId }, this)
    },

    onClick(e) {
      const time = this.mapPositionToTime(
        e.clientX - this.barContainer.left,
      ).format(this.timeFormat)

      this.onBarEvent({ event: e, type: e.type, time, rowId: this.rowId }, this)
    },

    onDblclick(e) {
      const time = this.mapPositionToTime(
        e.clientX - this.barContainer.left,
      ).format(this.timeFormat)

      this.onBarEvent({ event: e, type: e.type, time, rowId: this.rowId }, this)
    },

    onMousedown(e) {
      e.preventDefault()

      if (e.button === 2) {
        return
      }

      if (!this.isLocalImmobile && !this.barConfig.isShadow) {
        this.setDragLimitsOfGanttBar(this)
        // initialize the dragging on next mousemove event:
        window.addEventListener('mousemove', this.onFirstMousemove, {
          once: true,
        })
        // if next mousemove happens after mouse up (if user just presses mouse button down, then up, without moving):
        window.addEventListener(
          'mouseup',
          () => window.removeEventListener('mousemove', this.onFirstMousemove),
          { once: true },
        )
      }
      const time = this.mapPositionToTime(
        e.clientX - this.barContainer.left,
      ).format(this.timeFormat)

      this.onBarEvent({ event: e, type: e.type, time, rowId: this.rowId }, this)
    },

    onFirstMousemove(e) {
      this.isMainBarOfDrag = true
      // this method is injected here by GGanttChart.vue, and calls initDrag()
      // for all GGanttBars that belong to the same bundle as this bar:
      this.initDragOfBarsFromBundle(this, e)
    },

    /* --------------------------------------------------------- */
    /* ------------- METHODS FOR DRAGGING THE BAR -------------- */
    /* --------------------------------------------------------- */
    initDrag(e) {
      // "e" must be the mousedown event
      this.isDragging = true
      this.barStartBeforeDrag = this.bar[this.barStartKey]
      this.barEndBeforeDrag = this.bar[this.barEndKey]

      let barX = this.$refs['g-gantt-bar'].getBoundingClientRect().left

      this.cursorOffsetX = e.clientX - barX
      let mousedownType = e.target.className

      switch (mousedownType) {
        case 'g-gantt-bar-handle-left':
          if (!this.isHandleLeftDisabled) {
            document.body.style.cursor = 'w-resize'
            this.mousemoveCallback = this.dragByHandleLeft
          } else {
            document.body.style.cursor = 'not-allowed'

            return
          }
          break
        case 'g-gantt-bar-handle-right':
          if (!this.isHandleRightDisabled) {
            document.body.style.cursor = 'e-resize'
            this.mousemoveCallback = this.dragByHandleRight
          } else {
            document.body.style.cursor = 'not-allowed'

            return
          }
          break
        default:
          if (!this.isHandleLeftDisabled && !this.isHandleRightDisabled) {
            this.mousemoveCallback = this.drag
          } else {
            return
          }
      }
      window.addEventListener('mousemove', this.mousemoveCallback)
      window.addEventListener('mouseup', this.endDrag)
    },

    getBarWidth(bar) {
      let xStart = this.mapTimeToPosition(moment(bar[this.barStartKey]))
      let xEnd = this.mapTimeToPosition(moment(bar[this.barEndKey]))

      return xEnd - xStart
    },

    drag(e) {
      const chart = e.target.closest('.g-gantt-chart')

      if (!chart) {
        return
      }
      let barWidth = this.$refs['g-gantt-bar'].getBoundingClientRect().width
      let newXStart =
        chart.scrollLeft +
        e.clientX -
        this.barContainer.left -
        this.cursorOffsetX
      let newXEnd = newXStart + barWidth

      if (this.isPosOutOfDragRange(newXStart, newXEnd)) {
        return
      }

      this.barStartMoment = this.mapPositionToTime(newXStart)
      this.barEndMoment = this.mapPositionToTime(newXEnd)
      this.manageOverlapping()
      this.onBarEvent({ event: e, type: 'drag', rowId: this.rowId }, this)
    },

    dragByHandleLeft(e) {
      const chart = e.target.closest('.g-gantt-chart')

      if (!chart) {
        return
      }
      let newXStart = chart.scrollLeft + e.clientX - this.barContainer.left
      let newStartMoment = this.mapPositionToTime(newXStart)

      if (
        this.barEndMoment.diff(newStartMoment, this.timeUnit) <= 1 ||
        this.isPosOutOfDragRange(newXStart, null)
      ) {
        return
      }
      this.barStartMoment = newStartMoment
      this.manageOverlapping()
      this.onBarEvent(
        { event: e, type: 'drag-to-left', rowId: this.rowId },
        this,
      )
    },

    dragByHandleRight(e) {
      const chart = e.target.closest('.g-gantt-chart')

      if (!chart) {
        return
      }
      let newXEnd = chart.scrollLeft + e.clientX - this.barContainer.left
      let newEndMoment = this.mapPositionToTime(newXEnd)

      if (
        newEndMoment.isSameOrBefore(this.barStartMoment, this.timeUnit) ||
        this.isPosOutOfDragRange(null, newXEnd)
      ) {
        return
      }
      this.barEndMoment = newEndMoment
      this.manageOverlapping()
      this.onBarEvent(
        { event: e, type: 'drag-to-right', rowId: this.rowId },
        this,
      )
    },

    isPosOutOfDragRange(newXStart, newXEnd) {
      if (newXStart && newXStart < 0) {
        return true
      }
      if (newXEnd > this.barContainer.width) {
        return true
      }
      if (
        newXStart &&
        this.dragLimitLeft !== null &&
        newXStart < this.dragLimitLeft + this.getMinGapBetweenBars()
      ) {
        return true
      }

      // if (
      //   newXEnd &&
      //   this.dragLimitRight !== null &&
      //   newXEnd > this.dragLimitRight - this.getMinGapBetweenBars()
      // ) {
      //   return true
      // }

      // todo закомментировал т.к. не правильно работало и вызвывало сбои при перетаскивании
      // if (
      //   moment(this.bar[this.barStartKey]).isAfter(this.barStartBeforeDrag) &&
      //   moment(this.bar[this.barStartKey])
      //     .add(1, this.timeUnit)
      //     .isAfter(this.barEndBeforeDrag)
      // ) {
      //   return true
      // }

      // todo закоментировал т.к. бар не правильно двигался в право
      // const isSqueezeToLeft =
      //   newXStart &&
      //   moment(this.bar[this.barStartKey]).isBefore(this.barStartBeforeDrag)
      // const isSqueezeToRight =
      //   newXEnd &&
      //   moment(this.bar[this.barEndKey]).isAfter(this.barEndBeforeDrag)
      //
      // const currentIndex = this.allBarsInRow.findIndex(bar => bar === this.bar)
      //
      // let otherBars = []

      // if (isSqueezeToRight) {
      //   otherBars = this.allBarsInRow.slice(currentIndex + 1)
      //
      //   if (otherBars.length) {
      //     let otherBarTotalWidth = otherBars
      //       .map(bar => this.getBarWidth(bar))
      //       .reduce((accumulator, currentValue) => accumulator + currentValue)
      //
      //
      //     if (newXEnd > this.barContainer.width - otherBarTotalWidth) {
      //       return true
      //     }
      //   }
      // } else if (isSqueezeToLeft) {
      //   otherBars = this.allBarsInRow.slice(0, currentIndex)
      //
      //   if (otherBars.length) {
      //     let otherBarTotalWidth = otherBars
      //       .map(bar => this.getBarWidth(bar))
      //       .reduce((accumulator, currentValue) => accumulator + currentValue)
      //
      //     if (newXStart < otherBarTotalWidth) {
      //       return true
      //     }
      //   }
      // }

      return false
    },

    endDrag(e) {
      let left = false,
        right = false,
        move = false

      switch (document.body.style.cursor) {
        case 'e-resize':
          right = true
          break
        case 'w-resize':
          left = true
          break
        default:
          move = true
          break
      }
      this.isDragging = false
      this.dragLimitLeft = null
      this.dragLimitRight = null
      document.body.style.cursor = 'auto'
      window.removeEventListener('mousemove', this.mousemoveCallback)
      window.removeEventListener('mouseup', this.endDrag)

      if (this.isMainBarOfDrag) {
        this.onDragendBar(e, this, { left, right, move })
        this.isMainBarOfDrag = false
      }
    },

    snapBack() {
      this.barStartMoment = moment(this.barStartBeforeDrag)
      this.barEndMoment = moment(this.barEndBeforeDrag)
    },

    manageOverlapping() {
      if (
        !this.ganttChartProps.pushOnOverlap ||
        this.barConfig.pushOnOverlap === false
      ) {
        return
      }
      let currentBar = this.bar
      let { overlapBar, overlapType } = this.getOverlapBarAndType(currentBar)

      while (overlapBar) {
        let minuteDiff
        let currentStartMoment = moment(currentBar[this.barStartKey])
        let currentEndMoment = moment(currentBar[this.barEndKey])
        let overlapStartMoment = moment(overlapBar[this.barStartKey])
        let overlapEndMoment = moment(overlapBar[this.barEndKey])

        switch (overlapType) {
          case 'left':
            minuteDiff =
              overlapEndMoment.diff(
                currentStartMoment,
                this.timeChildKey,
                true,
              ) + this.getMinGapBetweenBars()
            overlapBar[this.barEndKey] = currentStartMoment
              .subtract(this.getMinGapBetweenBars(), this.timeChildKey, true)
              .format(this.timeFormat)
            overlapBar[this.barStartKey] = overlapStartMoment
              .subtract(minuteDiff, this.timeChildKey, true)
              .format(this.timeFormat)
            break
          case 'right':
            minuteDiff =
              currentEndMoment.diff(
                overlapStartMoment,
                this.timeChildKey,
                true,
              ) + this.getMinGapBetweenBars()
            overlapBar[this.barStartKey] = currentEndMoment
              .add(this.getMinGapBetweenBars(), this.timeChildKey, true)
              .format(this.timeFormat)
            overlapBar[this.barEndKey] = overlapEndMoment
              .add(minuteDiff, this.timeChildKey, true)
              .format(this.timeFormat)
            break
          default:
            // eslint-disable-next-line
            console.warn(
              'One bar is inside of the other one! This should never occur while push-on-overlap is active!',
            )

            return
        }
        this.moveBarsFromBundleOfPushedBar(overlapBar, minuteDiff, overlapType)
        currentBar = overlapBar
        ;({ overlapBar, overlapType } = this.getOverlapBarAndType(overlapBar))
      }
    },

    getOverlapBarAndType(bar) {
      let barStartMoment = moment(bar[this.barStartKey])
      let barEndMoment = moment(bar[this.barEndKey])
      let overlapLeft, overlapRight, overlapInBetween
      let overlapBar = this.allBarsInRow.find(otherBar => {
        if (otherBar === bar) {
          return false
        }
        let otherBarStartMoment = moment(otherBar[this.barStartKey])
        let otherBarEndMoment = moment(otherBar[this.barEndKey])

        overlapLeft = barStartMoment.isBetween(
          otherBarStartMoment,
          otherBarEndMoment,
        )
        overlapRight = barEndMoment.isBetween(
          otherBarStartMoment,
          otherBarEndMoment,
        )
        overlapInBetween =
          otherBarStartMoment.isBetween(barStartMoment, barEndMoment) ||
          otherBarEndMoment.isBetween(barStartMoment, barEndMoment)

        return overlapLeft || overlapRight || overlapInBetween
      })
      // eslint-disable-next-line no-nested-ternary
      let overlapType = overlapLeft
        ? 'left'
        : // eslint-disable-next-line no-nested-ternary
        overlapRight
        ? 'right'
        : overlapInBetween
        ? 'between'
        : null

      return { overlapBar, overlapType }
    },

    // this is used in GGanttChart, when a bar from a bundle is pushed
    // so that bars from its bundle also get pushed
    moveBarByChildPointsAndPush(childPointCount, direction) {
      switch (direction) {
        case 'left':
          this.barStartMoment = moment(this.barStartMoment).subtract(
            childPointCount,
            this.timeChildKey,
            true,
          )
          this.barEndMoment = moment(this.barEndMoment).subtract(
            childPointCount,
            this.timeChildKey,
            true,
          )
          break
        case 'right':
          this.barStartMoment = moment(this.barStartMoment).add(
            childPointCount,
            this.timeChildKey,
            true,
          )
          this.barEndMoment = moment(this.barEndMoment).add(
            childPointCount,
            this.timeChildKey,
            true,
          )
          break
        default:
          // eslint-disable-next-line
          console.warn('wrong direction in moveBarByChildPointsAndPush')

          return
      }
      this.manageOverlapping()
    },

    /* --------------------------------------------------------- */
    /* ------- MAPPING POSITION TO TIME (AND VICE VERSA) ------- */
    /* --------------------------------------------------------- */
    mapTimeToPosition(time) {
      let timeDiffFromStart = moment(time).diff(
        this.chartStartMoment,
        this.timeUnit,
        true,
      )

      return (timeDiffFromStart / this.getTimeCount()) * this.barContainer.width
    },

    mapPositionToTime(xPos) {
      let timeDiffFromStart =
        (xPos / this.barContainer.width) * this.getTimeCount()

      if (this.timeUnit === 'days') {
        let duration = moment.duration(timeDiffFromStart, 'days')

        timeDiffFromStart = duration.asHours()
      }

      return moment(this.chartStartMoment).add(timeDiffFromStart, 'hours')
    },
  },
}
</script>
