var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "g-gantt-bar",
          class: [
            "g-gantt-bar",
            {
              "g-gantt-bar-immobile": _vm.isLocalImmobile,
              active: _vm.activeBars.includes(_vm.bar.id),
            },
          ],
          style: _vm.barStyle,
          on: {
            mouseenter: function ($event) {
              $event.stopPropagation()
              return _vm.onMouseenter($event)
            },
            mouseleave: function ($event) {
              $event.stopPropagation()
              return _vm.onMouseleave($event)
            },
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.onMousedown($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick($event)
            },
            dblclick: function ($event) {
              return _vm.onDblclick($event)
            },
            contextmenu: function ($event) {
              return _vm.onContextmenu($event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "g-gantt-bar-label" },
            [
              _vm._t(
                "bar-label",
                function () {
                  return [_vm._v(" " + _vm._s(_vm.barConfig.label || "") + " ")]
                },
                { bar: _vm.bar }
              ),
            ],
            2
          ),
          _vm.barConfig.handles
            ? [
                _c("div", {
                  class: [
                    "g-gantt-bar-handle-left",
                    { disabled: _vm.isHandleLeftDisabled },
                  ],
                }),
                _c("div", {
                  class: [
                    "g-gantt-bar-handle-right",
                    { disabled: _vm.isHandleRightDisabled },
                  ],
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.barConfig.noTooltip && (_vm.showTooltip || _vm.isDragging)
          ? _c(
              "div",
              { staticClass: "g-gantt-tooltip", style: _vm.tooltipStyle },
              [
                _c("div", { staticClass: "m-b-5" }, [
                  _vm._v(
                    "Время: " +
                      _vm._s(_vm.barStartText) +
                      " - " +
                      _vm._s(_vm.barEndText)
                  ),
                ]),
                _c("div", { staticClass: "m-b-5" }, [
                  _vm._v("Культура: " + _vm._s(_vm.getCultureName)),
                ]),
                _c("div", { staticClass: "m-b-5" }, [
                  _vm._v("Кол-во: " + _vm._s(_vm.bar.buffer_quota)),
                ]),
                _c("div", { class: { "m-b-5": _vm.getBarExporter } }, [
                  _vm._v(" Тип авто: " + _vm._s(_vm.barTruckTypes) + " "),
                ]),
                _vm.getBarExporter
                  ? _c("div", [
                      _vm._v("Поставщик: " + _vm._s(_vm.getBarExporter)),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }